import React from 'react'
import "./HeroSection.css";
import '../App.css';
import {Button} from './Button';

function HeroSection() {
  return (
    <div className='hero-container'>
          <video src='/videos/video_1.mp4' autoPlay playsInline loop muted>
      </video>
      <h1>Unlock value with our data science cosultancy and AI-powered solutions</h1>
      <h2>using artificial intelligence, machine learning, econometrics and scalable software</h2>
      <p>Are your company ready for the next level?</p>
      <div className='hero-btns'>
        <Button className='btn' buttonStyle='btn--outline' buttonSize='btn--large'>
          Get in touch
        </Button>
        {/* <Button className='btns' buttonStyle='btn--primary' buttonSize='btn--large'>
          Explore Career Opportunities 
        </Button> */}
      </div>
    </div>
  )
}

export default HeroSection