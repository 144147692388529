import React from 'react';
// import { variables } from '../Api_variables';
import './Contact.css'

// import HeroSection from "../components/HeroSection";
// import Cards from "../components/Cards";
import Footer from "../components/Footer";
// import Carousel from "../components/Carousel";
// import Offerings from "../components/Offerings";
// import Results from "../components/Results";
// import Clients from "../components/Clients";
// import CallToAction from "../components/CallToAction";

// const getCompanyData = () => {
//   fetch(variables.API_URL)
//     .then((data) = data.json())
//   .then((data) = console.log(data));
// }

function Contact() {



  // const contact_data = fetch(variables.API_URL).then((data) => data.json());
  // var contact_data = response.json();

  // let name = contact_data.name;
  // let vat = contact_data.vat;
  // let phoneNumber = contact_data.phone;
  let email = "contact@consultai.dk"; // contact_data.email
  let address = "Højbovej 1A, 1. sal, 8600 Silkeborg"; // contact_data.address + contact_data.zipcode + contact_data.city

  return (
    <div className='container-fluid'>


    <div className='contactContainer'>

      <div className='col-lg-6 contactInfo contactInfoTable'>
        <table className='tableModContact' cellspacing="0" cellPadding="3">
          <thead>
          <h3 className=' contactHeader'>CONTACT</h3>
          </thead>
          <tbody className='tdText'>
            <tr>
              <th className='testttt'>
                Phone:
              </th>
              <td className='trSpacing'>
                (+45) 41 83 28 86 {/* {contact_data.phoneNumber} */}
              </td>
            </tr>
            <tr>
              <th>
                Email:
              </th>
              <td className='trSpacing'>
                {email}
              </td>
            </tr>
            <tr>
              <th>
                Office:
              </th>
              <td className='trSpacing'>
                {address}
              </td>
            </tr>
          </tbody>
        </table>

      </div>
      {/* <div className='col-lg-6 contactInfo'>
      <form className=''>
        <h3 className='contactHeader'>Have a question or want a consultation? Get in touch.</h3>
      <div class="form-group">
          <label for="fname"></label>
          <input type="fname" class="form-control" id="fname" placeholder="First Name"/>
        </div>
        <div class="form-group">
          <label for="lname"></label>
          <input type="lname" class="form-control" id="lname" placeholder="Last Name"/>
        </div>
        <div class="form-group">
          <label for="companyName"></label>
          <input type="companyName" class="form-control" id="companyName" placeholder="Company Name" required/>
        </div>
        <div class="form-group">
          <label for="email"></label>
          <input type="email" class="form-control" id="email" placeholder="E-mail" required/>
        </div>
        
        <div class="form-group divSpaceBelow">
          <label for="exampleFormControlTextarea1"></label>
          <textarea class="form-control" id="formInput" placeholder='Anything else we should know?' rows="3" required></textarea>
        </div>
        <button type="submit" class="btn btn-primary mb-2">Submit Request</button>
        <h1>LAV EN RECAPCHTA!</h1>
      </form>
      </div> */}
    </div>
     
      
      <Footer/>
    </div>
  )
}

export default Contact