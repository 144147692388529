import React from 'react';
// import { Link } from 'react-router-dom';
import CardItem from './CardItem';
import './Cards.css';

function Cards() {
  return (
    <div className='casesContainer container-fluid marketing'>
        <h1 className='servicesHeader'><b>Cases</b></h1>
        <div className='cards__container'>
            <div className='cards_wrapper'>
                <ul className='cards_items'>
                  <CardItem 
                      src="images/arla_cases_image.png" 
                      text="Setting New Standards for Promotion Planning through ML/AI." 
                      label="AI in Supply Chain and Promotion Planning, FMCG, Arla Foods" 
                      path="/UseCases" />
                  {/* <CardItem 
                    src="images/FMI_cases_image.png" 
                    text="The Danish Ministry of Defense Acquisitions and Logistics Organization" 
                    label="ETL, Automation, Data Modelling and Business Intelligence TECHNICAL DEBT, DALO" 
                    path="/UseCases" /> */}
                </ul>
            </div>
            <div className='cards_wrapper'>
                <ul className='cards_items'>
                  <CardItem 
                    src="images/FMI_cases_image.png" 
                    text="Transforming the Danish Ministry of Defense through AI and Data." 
                    label="GenAI, Data Automation and Business Intelligence, Logistics, DALO" 
                    path="/UseCases" />
                  {/* <CardItem 
                      src="images/arla_cases_image.png" 
                      text="Modelling Consumer Price Responses Through ML/AI in Inflationary Times" 
                      label="PRICING and PROMOTION, FMCG, Arla Foods" 
                      path="/UseCases" /> */}
                </ul>
            </div>

        </div>
    </div>
  )
}

export default Cards