import React from 'react'
import Footer from '../components/Footer'

function Team() {
  return (
    <div>Team


        <Footer/>        
    </div>
  )
}

export default Team