import React from 'react'
import { NavLink } from 'react-router-dom'
// import { Button } from './Button';
import './Navbar.css';

function Navbar() {
  return (
    <div className='navBarSettings'>
    <nav className="navbar navbar-expand-lg bg-body-tertiary navBarSettings" >
        <div className="container-fluid">
            <NavLink className="nav-link" aria-current="page" to="/">
                <p className="navbar-brand logoText"><b>CAi | Consult Ai</b></p>
            </NavLink>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto ms-2 mb-lg-0 ">
                {/* <li className="nav-item"> */}
                {/* <NavLink className="nav-link" aria-current="page" to="/">
                    Home
                </NavLink> */}
                {/* <a className="nav-link"  href="/">Home</a> */}
                {/* </li> */}
                <li className="nav-item ">
                    <NavLink className="nav-link butn" aria-current="page" to="/About">
                        <b className=''>ABOUT</b>
                    </NavLink>
                </li>
                <li className="nav-item ">
                    <NavLink className="nav-link butn" aria-current="page" to="/Services">
                        <b className=''>SERVICES</b>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link butn" aria-current="page" to="/UseCases">
                        <b className=''>CASES</b>
                    </NavLink>
                </li>
                {/* <li className="nav-item">
                <NavLink className="nav-link" aria-current="page" to="/UseExamples">
                    Industry Examples
                </NavLink>
                </li> */}
                <li className="nav-item">
                    <NavLink className="nav-link butn" aria-current="page" to="/Contact">
                        <b className=''>CONTACT</b>
                    </NavLink>
                </li>
                {/* <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    About
                </a>
                <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                        <NavLink className="dropdown-item" to="/About">
                            About Us
                        </NavLink>
                    </li>
                    <li>                     
                        <NavLink className="dropdown-item" to="/About">
                            Contact
                        </NavLink>
                    </li>
                    {/* <li><a className="dropdown-item" href="/Team">Team</a></li> */}
                    {/*<li>
                        <NavLink className="dropdown-item" to="/Team">
                                Team
                        </NavLink>
                    </li>
                </ul>
                </li> */}
                {/* <li class="nav-item">
                <a class="nav-link disabled" aria-disabled="true">Disabled</a>
                </li> */}
            </ul>
            {/* <form class="d-flex" role="search">
                <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search"></input>
                <button class="btn btn-outline-success" type="submit">Search</button>
            </form> */}
            </div>
        </div>
    </nav>
    </div>
  )
}

export default Navbar