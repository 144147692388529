import Navbar from './components/Navbar';
import {Home} from './pages/Home';
import Services from './pages/Services';
import About from './pages/About';
import Contact from './pages/Contact';
import PageNotFound from './pages/PageNotFound';
import Team from './pages/Team';
import SignUp from './pages/SignUp';
import UseExamples from './pages/UseExamples';
import UseCases from './pages/UseCases';
// import ScrollToTop from './components/ScrollToTop';

import './App.css';
import {Routes, Route, BrowserRouter as Router, useLocation} from 'react-router-dom';
import {useLayoutEffect} from 'react';

const Wrapper = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
} 


function App() {
  return (
    <Router>
      <div>
        <Wrapper>
          <Navbar/>
          <Routes>
          {/* <ScrollToTop/> */}
              <Route path='/' element={<Home/>} />
              <Route path='/services' element={<Services/>}></Route>
              <Route path='/usecases' element={<UseCases/>}></Route>
              <Route path='/examples' element={<UseExamples/>}></Route>
              
              <Route path='/about' element={<About/>}></Route>
              <Route path='/contact' element={<Contact/>}></Route>
              <Route path='/team' element={<Team/>}></Route>
              <Route path='/sign-up' element={<SignUp/>}></Route>
              <Route path='/page-not-found' element={<PageNotFound/>}></Route>
          </Routes>
        </Wrapper>
      </div>
    </Router>
  );
}

export default App;
