import React from 'react'
import Footer from '../components/Footer'

function SignUp() {
  return (
    <div>SignUp


        <Footer/>        
    </div>
  )
}

export default SignUp