import React from 'react'
import Footer from '../components/Footer'
import ServicesLong from '../components/ServicesLong'
import AlgoServicesLong from '../components/AlgoServicesLong'

function Services() {
  return (
    <div>
        <ServicesLong/>
        <AlgoServicesLong/>
        <Footer/>
    </div>
  )
}

export default Services