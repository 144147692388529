import React from 'react'
import "./CallToAction.css"
import { NavLink } from 'react-router-dom'

function CallToAction() {
  return (
    <div className='container-fluid'>

        <div className='row containerCtA'>
          <div className='col-xs-6 col-md-4 AnalyticsTextContainer'>
            <h5 className='AnalyticsHeader'><b>Unlock Your Analytics Potential with Us Today!</b></h5>
            <p className='AnalyticsText'>
              Don't wait another moment to supercharge your analytics journey. 
              Whether you're already on the path or just starting, we're here to propel you forward. 
              From day one, we ensure your data and AI strategies align perfectly with your business goals. 
              We're not just data scientists; we're business data scientists, dedicated to delivering tangible value through end-to-end data science projects. 
            </p>
            <p className='AnalyticsText2'>
              What sets us apart? We want to be your end-to-end advanced analytics partner delivering the full value potential.
              Furthermore, we differentiate between predictive analytics and causal analytics, a rare expertise in the industry today.
            </p>
            <div className='centerClasso'>
            <div className='btnContainer3'>
              <NavLink to='/contact' className='RemoveUnderline'>
                <button type="submit" className="btn btn-primary mb-2 btns2"><b>Get Started Now</b></button>
              </NavLink>
            </div>
            </div>
        </div>


        <div className=' col-xs-6 col-md-4 AnalyticsImgContainer'>
          <img src='images/AnalyticsJourneyInfographic2.png' className='AnalyticsImg2' id='AAJ' alt='Analytics Journey'></img>
        </div>
      </div>
    </div>
  )
}

export default CallToAction