import React from 'react'
import "./Services.css"

function Offerings3() {
  return (
    <div>

        <div className='flexing'>

            <div className='section yellow'>
                <h3 className='title'>End-to-End Data Science</h3>
                <div className='flexTextBoxes'>
                    <ol>
                        <li>                    
                            <div className='flexTextItem'>
                                <h4 className='textTest'>Identify AI Opportunities</h4>
                                <p className='textTest'>Uncover potential areas where AI can drive significant value for your business through thorough analysis and opportunity identification.</p>
                            </div>
                        </li>  
                        <li>
                            <div className='flexTextItem'>
                                <h4 className='textTest'>Data Strategy and Assessment</h4>
                                <p className='textTest'>Develop a robust data strategy aligned with your business goals and conduct assessments to ensure data quality, reliability, and relevance.</p>
                            </div>
                        </li>  
                        <li>
                            <div className='flexTextItem'>
                                <h4 className='textTest'>Data Engineering to Enable ML</h4>
                                <p className='textTest'>Build a strong foundation for Machine Learning by implementing efficient data engineering practices, ensuring your data is ready for advanced analytics.</p>
                            </div>
                        </li> 
                        <li> 
                            <div className='flexTextItem'>
                                <h4 className='textTest'>Tailored Machine Learning Models</h4>
                                <p className='textTest'>Craft personalized Machine Learning models designed to address your specific challenges and opportunities, providing tailored solutions for optimal performance.</p>
                            </div>
                        </li>  
                        <li>
                            <div className='flexTextItem'>
                                <h4 className='textTest'>Data Visualization and Reporting</h4>
                                <p className='textTest'>Transform complex data into actionable insights with intuitive visualizations and reporting, enabling informed decision-making across your organization.</p>
                            </div>
                        </li>  
                    </ol>
                </div>
            </div>


            <div className='section pink'>
                <h3 className='title'>Tailored AI-Enabled Software</h3>
                <div className='flexTextBoxes'>
                    <ol>
                        <li>
                            <div className='flexTextItem'> 
                                <h4 className='textTest'>Enable Data Insights through Software</h4>
                                <p className='textTest'>Integrate AI capabilities into your software solutions, unlocking valuable data insights that enhance the functionality and intelligence of your applications.</p>
                            </div>
                        </li>
                        <li>
                            <div className='flexTextItem'>
                                <h4 className='textTest'>Tailored Software-as-a-Service</h4>
                                <p className='textTest'>Receive customized Software-as-a-Service solutions, designed to meet your unique business requirements and scale seamlessly with your growth.</p>
                            </div>
                        </li>
                        <li>
                            <div className='flexTextItem'>
                                <h4 className='textTest'>Scalable Data & ML/AI Pipelines</h4>
                                <p className='textTest'>Establish scalable and efficient data pipelines, ensuring a seamless flow of data and Machine Learning processes to support your evolving business needs.</p>
                            </div>
                        </li>
                        <li>
                            <div className='flexTextItem'>
                                <h4 className='textTest'>Continuous Impact and MLOps</h4>
                                <p className='textTest'>Drive continuous impact through effective MLOps, optimizing and managing your Machine Learning operations for sustained success and measurable value.</p>
                            </div>
                        </li>
                        <li>
                            <div className='flexTextItem'>
                                <h4 className='textTest'>Seamless hand-over with Build-Operate-Transfer</h4>
                                <p className='textTest'>Experience a smooth transition with our Build-Operate-Transfer model, allowing for seamless handovers of AI solutions from development to full operational control.</p>
                            </div>
                        </li>
                    </ol>
                </div>
            </div>

            <div className='section green'>
                <h3 className='title'>Algorithm-as-a-Service</h3>
                <div className='flexTextBoxes'>
                    <ol>
                        <li>
                            <div className='flexTextItem'>
                                <h4 className='textTest'>Determine AI Impact Areas</h4>
                                <p className='textTest'>Identify strategic areas within your business where AI algorithms can make a significant positive impact, optimizing processes and decision-making.</p>
                            </div>
                        </li>
                        <li>
                            <div className='flexTextItem'>
                                <h4 className='textTest'>Structure and Collect Data</h4>
                                <p className='textTest'>Implement structured data collection processes, ensuring a reliable foundation for algorithm development and accurate model training.</p>
                            </div>
                        </li>
                        <li>
                            <div className='flexTextItem'>
                                <h4 className='textTest'>Algorithms Developed by Experts</h4>
                                <p className='textTest'>Benefit from AI algorithms crafted by seasoned experts, leveraging their deep understanding of Econometrics and Machine Learning for optimal results.</p>
                            </div>
                        </li>
                        <li>
                            <div className='flexTextItem'>
                                <h4 className='textTest'>Tailored ML/AI maintained on Our infrastructure</h4>
                                <p className='textTest'>Enjoy the advantage of having your Machine Learning and AI algorithms tailored and maintained by our expert team at CAi, ensuring continuous improvement and relevance.</p>
                            </div>
                        </li>
                        <li>
                            <div className='flexTextItem'>
                                <h4 className='textTest'>Seamless access integration or direct estimation delivery</h4>
                                <p className='textTest'>Choose between seamless integration for direct access to AI capabilities or opt for convenient direct estimation delivery, providing flexibility based on your business needs.</p>
                            </div>
                        </li>
                    </ol>
                </div>
            </div>
        </div>        
    </div>
  )
}

export default Offerings3