import React from 'react'
import { NavLink } from 'react-router-dom'

function AlgoServicesLong() {
  return (
    <div class="container-fluid serviceAlgoContainer">

    <h2 className='servicesHeader'> ML/AI Services </h2>
    <div className='serviceAlgoFlexContainer'>
      <div class="serviceAlgoContainer2">
        <div class="serviceAlgoFlexItem">
          <svg className="bd-placeholder-img servicesAlgoImages rounded-circle" xmlns="http://www.w3.org/2000/svg" width="140" height="140" fill="currentColor" class="bi bi-diagram-2-fill" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H11a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 5 7h2.5V6A1.5 1.5 0 0 1 6 4.5v-1zm-3 8A1.5 1.5 0 0 1 4.5 10h1A1.5 1.5 0 0 1 7 11.5v1A1.5 1.5 0 0 1 5.5 14h-1A1.5 1.5 0 0 1 3 12.5v-1zm6 0a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1A1.5 1.5 0 0 1 9 12.5v-1z"/>
          </svg>
          <h2 className="fw-normal serviceAlgoFlexItemText">Predictive Analytics</h2>
          <ul className='longListBullets longListItemBox1'>
            <li>
                Fraud Detection
            </li>
            <li>
                Identify competing products
            </li>
            <li>
                Recommender Systems
            </li>
            <li>
                Personalized Predictions
            </li>
            <li>
                Precision Medicine
            </li>
            <li>
                etc.
            </li>
          </ul>
        </div>
        <div class="serviceAlgoFlexItem">
          <svg className="bd-placeholder-img servicesAlgoImages rounded-circle" xmlns="http://www.w3.org/2000/svg" width="140" height="140" fill="currentColor" class="bi bi-crosshair" viewBox="0 0 16 16">
            <path d="M8.5.5a.5.5 0 0 0-1 0v.518A7.001 7.001 0 0 0 1.018 7.5H.5a.5.5 0 0 0 0 1h.518A7.001 7.001 0 0 0 7.5 14.982v.518a.5.5 0 0 0 1 0v-.518A7.001 7.001 0 0 0 14.982 8.5h.518a.5.5 0 0 0 0-1h-.518A7.001 7.001 0 0 0 8.5 1.018V.5Zm-6.48 7A6.001 6.001 0 0 1 7.5 2.02v.48a.5.5 0 0 0 1 0v-.48a6.001 6.001 0 0 1 5.48 5.48h-.48a.5.5 0 0 0 0 1h.48a6.002 6.002 0 0 1-5.48 5.48v-.48a.5.5 0 0 0-1 0v.48A6.001 6.001 0 0 1 2.02 8.5h.48a.5.5 0 0 0 0-1h-.48ZM8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"/>
          </svg>

          <h2 className="fw-normal serviceAlgoFlexItemText">ML/AI-Based Forecasting </h2>
          <ul className='longListBullets longListItemBox2'>
            <li>
                Demand Forecasting
            </li>
            <li>
                Promotion Demand Phasing
            </li>
            <li>
                etc.
            </li>
          </ul>
        </div>
        <div class="serviceAlgoFlexItem">
          <svg className="bd-placeholder-img servicesAlgoImages" xmlns="http://www.w3.org/2000/svg" width="140" height="140" fill="currentColor" class="bi bi-graph-up" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M0 0h1v15h15v1H0V0Zm14.817 3.113a.5.5 0 0 1 .07.704l-4.5 5.5a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61 4.15-5.073a.5.5 0 0 1 .704-.07Z"/>
          </svg>
          <h2 className="fw-normal serviceAlgoFlexItemText">Uplift Modeling</h2>
          <ul className='longListBullets longListItemBox3'>
            <li>
                Campaign Uplift
            </li>
            <li>
                Churn Uplift
            </li>
            <li>
                Price Elasticity Estimation
            </li>
            <li>
                Heterogeneous Consumer/User Uplift (Double ML)
            </li>
            <li>
                Synthetic Control Methods
            </li>
            <li>
                Policy Learning
            </li>
            <li>
                etc.
            </li>
          </ul>
        </div>
        {/* <div className="serviceAlgoFlexItem">
          <svg className="bd-placeholder-img center1 " xmlns="http://www.w3.org/2000/svg" width="140" height="140" fill="currentColor" class="bi bi-bar-chart-steps" viewBox="0 0 16 16">
            <path d="M.5 0a.5.5 0 0 1 .5.5v15a.5.5 0 0 1-1 0V.5A.5.5 0 0 1 .5 0zM2 1.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-1zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1zm2 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5v-1zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1z"/>
          </svg>
          <h2 className="fw-normal">Interpretable AI</h2>
        </div> */}
      </div>

    </div>
    <div className='btnContainer'>
            <NavLink to='/contact' className='RemoveUnderline'>
              <button type="submit" className="btn btn-primary mb-2 btns2"><b className='centerBtnText'>Contact Us Today</b></button>
            </NavLink>
        </div>
    <hr class="featurette-divider"/>
  </div>
  )
}

export default AlgoServicesLong