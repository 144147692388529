import React from 'react'
import './Cases.css'
import CallToAction from './CallToAction'

/* Remember to fill in: Methods, Role, Results, Also about Nature and Cusomer Gains. */

function CasesLong() {
  return (
    <div>
        <div class="row featurette">
            <div class="col-md-7 featurette-box-type1">
                    <div className='pushText'>
                        <h2 className="featurette-heading fw-normal lh-1">
                            Setting New Standards for Promotion Planning <span class="span-color"> through ML/AI at Arla Foods.</span>
                        </h2>
                        <p className="lead">
                            In the dynamic world of FMCG, where sales success hinges on effective campaigns and promotions, 
                            precision in demand and promotion planning is paramount. For Arla Foods, a leading dairy company 
                            operating globally across diverse markets, the challenge lay in managing numerous products with 
                            distinct seasonal patterns, various production times, and varying campaign lengths.
                        </p>
                        <div className='casesFlexContainer'>

                            <div className='casesFlexItem casesFlextItem1'>
                                <h4>
                                    The Challenge
                                </h4>
                                <p className="lead">
                                    Campaigns introduce a level of uncertainty, particularly regarding consumer responses to 
                                    factors like price reductions. Accurate estimation of demand deviations is 
                                    critical—overestimation leads to wasteful supply, while underestimation risks leaving 
                                    customers empty-handed. The complexity multiplies as Arla operates across international 
                                    markets, each with multiple retailers, and a diverse range of dairy products.
                                </p>
                            </div>

                            <div className='casesFlexItem casesFlextItem2'>
                                <h4>Our Solution</h4>
                                <p className="lead">
                                    Consult Ai was entrusted with the task of revolutionizing Arla's demand planning 
                                    through an innovative Predictive Phasing model. Our goal was to automate processes 
                                    and enhance prediction accuracy, alleviating the challenges faced by demand planners.
                                </p>
                            </div>

                            <div className='casesFlexItem casesFlextItem3'>
                                <h4>The Approach</h4>
                                <p className="lead">
                                    We meticulously designed a tailored algorithm capable of seamlessly handling the intricacies 
                                    of Arla's extensive product range, diverse brands, and varied campaign types. This algorithm 
                                    provided precise predictions for the upcoming campaigns, optimizing the phasing of product 
                                    shipments to retailers.
                                </p>
                            </div>
                        </div>
                        <div className='casesFlexContainer'>
                            <div className='casesFlexItem casesFlextItem1'>
                                <h4>The Impact</h4>
                                <p className="lead">
                                    Our Predictive Phasing model achieved remarkable improvements in prediction accuracy, 
                                    surpassing benchmarks and Arla's current manual process. Upon implementation, this translates 
                                    into a substantial annual revenue increase of 4 million Euros across Arla's northern markets.
                                </p>
                            </div>

                            <div className='casesFlexItem casesFlextItem2'>
                                <h4>Additional Benefits</h4>
                                <p className="lead">
                                    Beyond revenue enhancement, our algorithm automated manual processes related to campaign 
                                    phasing, freeing up valuable time for demand planners. The ripple effects included averted 
                                    consumer and customer dissatisfaction, reduced environmental impact through minimized food 
                                    waste, and heightened efficiency in logistical planning.
                                </p>
                            </div>

                            <div className='casesFlexItem casesFlextItem3'>
                                <h4>ML Ops Integration</h4>
                                <p className="lead">
                                    In tandem with our solution, Consult Ai implemented a robust ML Ops setup, enabling continuous 
                                    monitoring and further development of the algorithm. This ensures that Arla remains at the 
                                    forefront of innovation, adapting to evolving market dynamics.
                                </p>
                            </div>
                        </div>
                        <p className="lead">
                            At Consult Ai, we don't just set new standards; we redefine possibilities, enabling our clients 
                            to navigate the complexities of their industry with confidence and precision.         
                        </p>
                    </div>
            </div>
            <div class="col-md-5 casesImgDiv1">
                <img src='./images/arla_cases_image.png' className='casesImg' alt='Arla Case'  />
            </div>
        </div>

        <div class="row featurette">
            <div class="col-md-7 order-md-2 featurette-box-type2">
                <div className='pushText'>
                <h2 class="featurette-heading fw-normal lh-1">
                    Transforming the Danish Ministry of Defense <span class="span-color"> through AI and Data Automation.</span>
                </h2>
                <p className='lead'>
                    In the intricate landscape of defense, where information flows are often hindered by the nature of work, 
                    modernizing the supply chain presents unique challenges. One key element to overcoming these challenges lies 
                    in enhancing internal data handling through automation and harnessing the power of generative AI. 
                    This not only eases workloads but also accelerates the development of Business Intelligence solutions, 
                    fostering informed decision-making.
                </p>
                <div className='casesFlexContainer'>
                    <div className='casesFlexItem casesFlextItem1'>
                        <h4>The Challenge</h4>
                        <p className='lead'>
                        The Danish Ministry of Defense Acquisition and Logistics Organisation faced the complexities of modernizing 
                        its supply chain, where vital information was often siloed, impeding efficiency and decision-making processes.
                        </p>
                    </div>

                    <div className='casesFlexItem casesFlextItem2'>
                        <h4>Our Solution</h4>
                        <p className='lead'>
                            Consult Ai undertook the mission to improve the supply chain by integrating generative AI, aiming to 
                            automate the retrieval and generation of critical import and export information. This strategic move 
                            was poised to alleviate employees from an estimated 20 years of manual effort, equivalent to a significant 
                            cost savings of 1.5 million Euros.
                        </p>
                    </div>

                    <div className='casesFlexItem casesFlextItem3'>
                        <h4>Data Automation</h4>
                        <p className='lead'>
                            In addition to the generative AI implementation, we automated several data pipelines, streamlining 
                            the flow of information and ensuring accuracy in handling vast datasets crucial to day-to-day operations.
                        </p>
                    </div>
                </div>

                <div className='casesFlexContainer'>
                    <div className='casesFlexItem casesFlextItem1'>
                        <h4>Business Intelligence and Reporting</h4>
                        <p className='lead'>
                            Our solution extended beyond automation. Consult Ai delivered a robust Business Intelligence 
                            framework and a reporting solution. This not only provided valuable insights for decision-making 
                            but also served as a tool to identify and address potential technical debt within the organization.
                        </p>
                    </div>

                    <div className='casesFlexItem casesFlextItem2'>
                    <h4>The Impact</h4>
                        <p className='lead'>
                            The integration of generative AI and data automation resulted in transformative outcomes for the 
                            Danish Ministry of Defense. The anticipated savings of 1.5 million Euros annually were realized, 
                            marking a substantial reduction in manual workload and associated costs.
                        </p>
                    </div>

                    <div className='casesFlexItem casesFlextItem3'>
                        <h4>Empowering Decision-Making</h4>
                        <p className='lead'>
                            The Business Intelligence solutions provided by Consult Ai empowered the organization with timely 
                            and accurate insights. This, in turn, facilitated informed decision-making and laid the groundwork 
                            for continuous improvements in operational efficiency.
                        </p>
                    </div>
                </div>

                <p className='lead'>
                    At Consult Ai, we thrive on challenges, turning complexities into opportunities for innovation. 
                    Our commitment is not only to meet expectations but to exceed them, leaving a lasting impact on our 
                    clients' operational excellence.
                </p>
                
            </div>
            </div>
        <div class="col-md-5 order-md-1 casesImgDiv2">
            <img src='./images/FMI_cases_image.png' className='casesImg2' alt='FMI Case'/>   
        </div>
    </div>


        {/* 
        <div class="row featurette">
        <div class="col-md-7">
            <h2 class="featurette-heading fw-normal lh-1">And lastly, this one. <span class="text-body-secondary">Checkmate.</span></h2>
            <p class="lead">And yes, this is the last block of representative placeholder content. Again, not really intended to be actually read, simply here to give you a better view of what this would look like with some actual content. Your content.</p>
        </div>
        <div class="col-md-5">
            <svg class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 500x500" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="var(--bs-secondary-bg)"/><text x="50%" y="50%" fill="var(--bs-secondary-color)" dy=".3em">500x500</text></svg>
        </div>
        </div>

        <hr class="featurette-divider"/>

        <div class="row featurette">
        <div class="col-md-7 order-md-2">
            <h2 class="featurette-heading fw-normal lh-1">Oh yeah, it's that good. <span class="text-body-secondary">See for yourself.</span></h2>
            <p class="lead">Another featurette? Of course. More placeholder content here to give you an idea of how this layout would work with some actual real-world content in place.</p>
        </div>
        <div class="col-md-5 order-md-1">
            <svg class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 500x500" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="var(--bs-secondary-bg)"/><text x="50%" y="50%" fill="var(--bs-secondary-color)" dy=".3em">500x500</text></svg>
        </div>
        </div> */}

        <CallToAction/>
    </div>
  )
}

export default CasesLong