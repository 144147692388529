import React from 'react'
import './AboutShort.css'
import { NavLink } from 'react-router-dom'

import { Link } from "react-router-dom"

function AboutShort() {
  return (
    <div className='aboutShortContainer'>
        <h2 className='aboutHeader'>
            <b>Who we are</b>
        </h2>

        <div className='AboutTextContainer'>
          <div className='AboutFlexBoxItem'>
            <h3 className='aboutSmallHeader'>
              ML/AI Experts
            </h3>
            <p className='aboutText'> 
              We're experts in Econometrics, AI, and Machine Learning, blending academic knowledge with industry experience. We understand the nuances of AI and focus on delivering the right solutions for your unique challenges.
            </p>
          </div>
          <div className='AboutFlexBoxItem'>
            <h3 className='aboutSmallHeader'>
              Democratizing Data and AI
            </h3>
            <p className='aboutText'> 
              We solve the issues of data silos and isolated AI models with shared ML/AI platforms. Choose from our Build-Operate-Transfer (BOT) or Software-as-a-Service (SaaS) solutions to unleash the value of data and AI.
            </p>
          </div>
          <div className='AboutFlexBoxItem'>
            <h3 className='aboutSmallHeader'>
              End-to-End AI Partnership
            </h3>
            <p className='aboutText'> 
              We handle the technical side of AI so you can focus on your core competencies. Whether you're a startup or a large corporation, our goal is to drive tangible benefits for your business.  
            </p>
          </div>

          
        </div>
        <div className='butn2Container '>
              <Link className="nav-link" to="/About">
                <p className='butn2 AboutFlexBoxItem2'><b className='nav-item'>LEARN MORE</b></p>
              </Link>
          </div>
        {/* <div className=''>

        </div> */}

        {/* <div className='btnContainer2'>
            <button type="submit" className="btn btn-primary mb-2 btns2"><b>Learn more</b></button>
        </div> */}

        {/* <p className='aboutText'>
          Your company should focus on it's key competencies and reap the benefits of AI and let us worry about the technical side.
          
          With a background from leading academic institutions and industry experience, we have the technical provess and experience to be a valuable partner to your company. Our focus in on the benefits to your business from small companies starting out to larger corporations far in their journey. We are also keen to help a company division show the rest of the company what can be achieved with Advanced Analytics, but without the usual tiresome and slow processes.


          Security is important to us!          
        </p> */}

        <h2 className='aboutHeader2'>
          We partner with ambitious clients, are you ready to join them?   
        </h2>

        <div className='clientContainer'>
        <div className='col-xs-6 col-md-4 clientImgBox'>
                <img src='images/arla_logo_rgb_72dpi.png' id='arla' alt='client logo' className='clientImg'></img>
            </div>

            <div className='col-xs-6 col-md-4 clientImgBox'>
                <img src='images/dalo_logo.png' id='dalo' alt='client logo' className='clientImg2'></img>
            </div>

        </div>

        <div className='btnContainer'>
            <NavLink to='/contact' className='RemoveUnderline'>
              <button type="submit" className="btn btn-primary mb-2 btns2"><b className='centerBtnText'>Get in touch</b></button>
            </NavLink>
        </div>


    </div>
  )
}

export default AboutShort