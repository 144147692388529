import React from 'react'
import { NavLink } from 'react-router-dom'

function AboutLong() {
  return (
    <div className='aboutShortContainer'>
        <h2 className='aboutHeader'>
            Who we are
        </h2>

        <div className='AboutTextContainer'>
        <div className='AboutFlexBoxItem'>
            <h3 className='aboutSmallHeader'>
                ML/AI Experts with a Focus on Algorithms and Software
            </h3>
            <p className='aboutText'> 
                We are a team of seasoned experts in the fields of Econometrics, Artificial Intelligence, and Machine Learning, with strong academic backgrounds and extensive industry experience. Our collective expertise empowers us to deliver enterprise-level solutions that can truly transform your business.
            </p>
            <p className='aboutText'>  
                Much like a skilled carpenter, we understand the nuanced strengths and limitations of Machine Learning and AI methods. We know how to ask the right questions and deploy the most suitable tools to generate results. We won't attempt to use a hammer to solve problems that require a more delicate touch. Our commitment is to provide tailored, effective solutions to your unique challenges.
            </p>
        </div>
        <div className='AboutFlexBoxItem'>
            <h3 className='aboutSmallHeader'>
                Democratizing Data and Unleashing the Power of AI to Drive Value
            </h3>
            <p className='aboutText'> 
                In today's data-driven world, data silos, multiple sources of truth, and isolated AI models can hinder progress and insight. We believe in the power of shared Machine Learning and AI platforms as the solution to these issues. Our approach combines sound data and AI strategies with flexibility, offering you two key options:
            </p>
            <p className='aboutText'> 
            <ol className='listNumbers'>
            <li className=''>
                <b>Build-Operate-Transfer (BOT):</b> We can help you establish, run, and eventually transition your AI infrastructure as you see fit.
            </li>
            <li>
                <b>Software-as-a-Service (SaaS):</b> You can choose to host your custom ML/AI models and software on our robust infrastructure, enabling seamless accessibility and scalability.
            </li>
            </ol>
            </p>
            <p className='aboutText'> 
                Our mission is to democratize data and AI, ensuring that your organization can harness their full potential to create value.            
            </p>
        </div>
        <div className='AboutFlexBoxItem'>
            <h3 className='aboutSmallHeader'>
                Start Small, Scale Fast with Our End-to-End AI Partnership
            </h3>
            <p className='aboutText'> 
                At our core, we believe that your company's focus should be on its core competencies. Let us handle the technical intricacies and complexities of AI and Machine Learning so that you can fully reap the benefits.
            </p>
            <p className='aboutText'> 
                Our approach caters to businesses of all sizes, from startups embarking on their AI journey to larger corporations that have advanced into sophisticated analytics. We are your dedicated partner, with a focus on driving tangible, sustainable benefits to your business.
            </p>
            <p className='aboutText'> 
                By choosing us, you can start small and scale fast, confidently navigating the AI landscape with a trusted ally by your side.            
            </p>
        </div>
        </div>
        <h2 className='aboutHeader2'>
          We partner with ambitious clients, are you ready to join them?   
        </h2>

        <div className='clientContainer'>
        <div className='col-xs-6 col-md-4 clientImgBox'>
                <img src='images/arla_logo_rgb_72dpi.png' id='arla' alt='client logo' className='clientImg'></img>
            </div>

            <div className='col-xs-6 col-md-4 clientImgBox'>
                <img src='images/dalo_logo.png' id='dalo' alt='client logo' className='clientImg2'></img>
            </div>

        </div>

        <div className='btnContainer'>
            <NavLink to='/contact' className='RemoveUnderline'>
              <button type="submit" className="btn btn-primary mb-2 btns2"><b className='centerBtnText'>Get in touch</b></button>
            </NavLink>
        </div>
        
    </div>
  )
}

export default AboutLong