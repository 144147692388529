import React from 'react'
import "./Services.css"
import { Link } from "react-router-dom"

function Offerings() {
  return (
    <div className='container-fluid .d-flex'>

      <h2 className='servicesHeader'><b>Our services</b></h2>

          <table className='tableColSize'>
            <thead className='tableHeader'>
              <tr>
                <th className='tableHeader1'>
                  End-to-End Data Science
                </th>
              </tr>
            </thead>        
            <tbody className='tableHeaderObjects'>
              <tr>
                
                <ol className='ol1'>
                  <div className='servicesShortFlexBox'>
                    <td className='servicesShortFlexItem firstItemInShortServices'><li className='ListCounter '></li><p className='serviceListText'>Identify AI Opportunities</p></td> 
                    <td className='servicesShortFlexItem'><li className='ListCounter'></li><p className='serviceListText'>Data Strategy and Assessment</p></td> 
                    <td className='servicesShortFlexItem'><li className='ListCounter'></li><p className='serviceListText'>Data Engineering to Enable ML</p></td>
                    <td className='servicesShortFlexItem'><li className='ListCounter'></li><p className='serviceListText'>Tailored Machine Learning Models</p></td>
                    <td className='servicesShortFlexItem'> <li className='ListCounter'></li><p className='serviceListText'>Data Visualization and Reporting</p></td>
                  </div>  
                </ol> 

              </tr>   
            </tbody> 
          </table>

          <h3 className='servicesDivider'> Reduce Complexity, Improve Decision-Making and Create Measurable Value through AI</h3>

          <table className='tableColSize'>
            <thead className='tableHeader'>
              <tr>
                <th className='tableHeader2'>
                  AI Enabled Software
                </th>
              </tr>
            </thead>        
                <tbody className='tableHeaderObjects'>
                <tr>
                  <ol className='ol1'>
                    <div className='servicesShortFlexBox'>
                      <td className='servicesShortFlexItem firstItemInShortServices'><li className='ListCounter'></li><p className='serviceListText'>Enable Data Insights through Software</p></td> 
                      <td className='servicesShortFlexItem'><li className='ListCounter'></li><p className='serviceListText'>Tailored Software-as-a-Service</p></td> 
                      <td className='servicesShortFlexItem'><li className='ListCounter'></li><p className='serviceListText'>Scalable Data & ML/AI Pipelines</p></td>
                      <td className='servicesShortFlexItem'><li className='ListCounter'></li><p className='serviceListText'>Continuous Impact and MLOps</p></td>
                      <td className='servicesShortFlexItem'><li className='ListCounter'></li><p className='serviceListText'>Seamless hand-over with Build-Operate-Transfer</p></td>
                    </div>
                  </ol>
                   
                </tr>   
            </tbody> 
          </table >

          <h3 className='servicesDivider'> AI on Your Terms: Empower Your Business and Unleash the Power of AI on Your Premise or Tap into Our Powerhouse </h3>

          <table className='tableColSize'>
            <thead className='tableHeader'>
              <tr>
                <th className='tableHeader2'>
                  Algorithm-as-a-Service
                </th>
              </tr>
            </thead>        
                <tbody className='tableHeaderObjects'>
                <tr>
                  <ol className='ol1'>
                    <div className='servicesShortFlexBox'>
                      <td className='servicesShortFlexItem firstItemInShortServices'><li className='ListCounter'></li><p className='serviceListText'>Determine AI Impact Areas</p></td> 
                      <td className='servicesShortFlexItem'><li className='ListCounter'></li><p className='serviceListText'>Structure and Collect Data</p></td> 
                      <td className='servicesShortFlexItem'><li className='ListCounter'></li><p className='serviceListText'>Algorithms Developed by Experts</p></td>
                      <td className='servicesShortFlexItem'><li className='ListCounter'></li><p className='serviceListText'>Tailored ML/AI maintained on Our Premises</p></td>
                      <td className='servicesShortFlexItem'><li className='ListCounter'></li><p className='serviceListText'>Seamless access integration or direct estimation delivery</p></td>
                    </div>
                  </ol>
                  
                </tr>   
            </tbody> 
          </table >

        <div className='butn3Container'>
          <Link className="nav-link " to="/Services">
            <p className='butn2 AboutFlexBoxItem3'><b className=''>LEARN MORE</b></p>
          </Link>
        </div>
        <div className='emptySpace'></div>
    </div>
  )
}

export default Offerings