import React, {Component} from "react";
import '../App.css';

import HeroSection from "../components/HeroSection";
import Cards from "../components/Cards";
import Footer from "../components/Footer";
import AlgoServices from "../components/AlgoServices";
import Services from "../components/Services";
import CallToAction from "../components/CallToAction";
import AboutShort from "../components/AboutShort";

export class Home extends Component{
    render(){
        return(
            <div>
                <HeroSection/>
                <AboutShort/>
                <Services/>
                <AlgoServices/>
                <Cards/>
                <CallToAction/>
                <Footer/>
            </div>
        )
    }
}