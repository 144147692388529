import React from 'react'
import Footer from '../components/Footer'
import AboutLong from '../components/AboutLong'

function About() {
  return (
    <div>
        <AboutLong/>
        <Footer/>
    </div>   
  )
}

export default About