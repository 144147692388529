import React from 'react'
import Footer from '../components/Footer'
import CasesLong from '../components/CasesLong'

function UseCases() {
  return (
    <div>
        <CasesLong/>
        <Footer/>
    </div>
  )
}

export default UseCases